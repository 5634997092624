/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body {
  margin: 0 !important;
  overflow: hidden;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  font-size: 14px;
}

div[cesium] {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.hide {
  display: none;
}

.cesium-widget-credits{
  display:none !important;
}

mat-toolbar {
  background-color: white !important;
  padding: 0 !important;
  height: 40px !important;
  width: 100% !important;
}

.mat-expansion-panel-header, .mat-expansion-panel-body {
  padding: 0 !important;
}

.module-title {
  color: #ACACBC !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.indicators {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  padding: 10px 0;

  div {
    font-size: 16px;
    display: flex !important;
    flex-direction: row !important;
    font-weight: 600 !important;
    align-items: center;
    margin: auto;

    .material-icons {
      color: rgba(0, 0, 0, 0.5) !important;
    }
  }
}

.sub-title {
  font-weight: 400;
  font-size: 12px;
  color: rgb(90, 90, 120);
}

.emergency-badge {
  position: absolute;
  top: 0;
  right: -15px;
  width: 8px;
  height: 8px;
  border-radius: 4px;
}

.popup-title {
  padding: 10px;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  width: 100%;
}
.popup-content {
  margin: 0;
  padding: 7px;
  font-size: 12px;
  color: rgba(0,0,0,0.7);
  border-top: 1px solid rgba(0,0,0,0.1);
}
.popup-value {
  color: black;
  font-weight: 600;
  text-align: right
}

.spacer {
  flex: 1 1 auto;
}
